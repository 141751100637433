module.exports = [{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.vatragel.com"},
    },{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":true},
    },{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/miroslav/Desktop/marko/vatra-blog/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#121212"},
    }]
